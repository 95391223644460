
/*  */
export const searchPartners = () => {
    /* input search */
    if (document.querySelector('#search-input')) {
        document.querySelector('#search-input').addEventListener('input', filterlist);
    }

    function filterlist() {
        const searchInput = document.querySelector('#search-input');
        const filter = searchInput.value.toLowerCase();
        const listItems = document.querySelectorAll('.table-row');
        listItems.forEach(item => {
            let text = item.textContent;
            if (text.toLowerCase().includes(filter.toLowerCase())) {
                item.style.display = '';
            } else {
                item.style.display = 'none';

            }
        });
    }
    /* select search  */
    if (document.querySelector('#select_search_input')) {
        document.querySelector('#select_search_input').addEventListener('change', filterSelectlist);

    }

    function filterSelectlist() {
        const searchInput = document.querySelector('#select_search_input');
        const filter = searchInput.value.toLowerCase();
        const listItems = document.querySelectorAll('.table-row');

        if ((filter.toLowerCase() == 'sector') || (filter.toLowerCase() == 'secteur')) {
            listItems.forEach(item => {
                let text = item.textContent;
                item.style.display = '';

            });
        } else {
            listItems.forEach(item => {
                let text = item.textContent;
                if (text.toLowerCase().includes(filter.toLowerCase())) {
                    item.style.display = '';
                } else {
                    item.style.display = 'none';

                }
            });
        }
    }




    const customContainer = document.querySelector('.iso-container');
    if (customContainer) {
        const isotope = new Isotope(customContainer, {
            itemSelector: '.card',
            layoutMode: 'fitRows',
            transitionDuration: '0.6s',
            stagger: 30,
            animationOptions: {
                duration: 750,
                easing: 'linear',
                queue: false,
            },
        });

        const searchInput = document.getElementById('search-input');

        searchInput.addEventListener('input', function () {
            const searchString = searchInput.value.toLowerCase();

            isotope.arrange({
                filter: function (itemElem) {
                    const title = itemElem.querySelector('.optionItems:nth-child(1)').textContent.toLowerCase();
                    const description = itemElem.querySelector('.optionItems:nth-child(2)').textContent.toLowerCase();
                    const isMatch = title.includes(searchString) || description.includes(searchString);
                    return isMatch || searchString === '';
                },
            });
        });
    }

};