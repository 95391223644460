require('./bootstrap');
require('./video');
require('./form');
// require('./loading');


import { moveOnScroll } from './moveOnScroll';
import { carousel } from './carousel';
import { centreOfInterest } from './centreOfInterest';
import { searchPartners } from './searchPartners';

import { tns } from "tiny-slider/src/tiny-slider.js";
carousel();
searchPartners();
moveOnScroll();
centreOfInterest();


(() => {
  ('use strict');
  const navBtn = document.querySelector('.navbar-toggler');
  navBtn.addEventListener('click', function () {
    document.getElementById('header').classList.toggle('bg-nav-btn');
    // if (window.innerWidth <= 991) {
    //   navBtn.classList.toggle('bg-nav-btn');
    // }
  });


  //   var nav_item = document.getElementsByClassName(".nav-item.dropdown");

  var nav_item = document.querySelectorAll('.nav-item.dropdown');

  for (var i = 0; i < nav_item.length; i++) {
    nav_item[i].addEventListener('click', function () {
      if (window.innerWidth <= 1200) {
        this.classList.toggle('hover');

      }
    }, false);
  }





})();

document.addEventListener('DOMContentLoaded', function () {

  //ISO ANIMATION FOR PARTNERS

  let customContainer = document.querySelector(".iso-container");
  if (customContainer) {

    let isotope = new Isotope(customContainer, {
      filter: "*",percentPosition: true,
      transitionDuration: "2s",
      animationOptions: {
        duration: 3500,
        queue: true
      },
      layoutMode: "masonry",
      // fitRows: {
      //   gutter: 0
      // },
      masonry: {
        columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer'
      },
      itemSelector: '.grid-item',
      percentPosition: true
    });
    let selectFilter = document.querySelector("#custom-filter");
    if (selectFilter) {


      selectFilter.addEventListener("change", function () {

        let customSelector = this.value;
        isotope.arrange({
          filter: selectElement.value,
          transitionDuration: "1.3s",
          animationOptions: {
            duration: 3500,
            queue: false
          },
          layoutMode: "fitRows",
          fitRows: {
            gutter: 0
          },
          masonry: {
            columnWidth: 100,
            isFitWidth: true
          }
        });

      });

      var selectElement = document.querySelector('.custom-select-partner');
      var changeEvent = new Event('change');
      selectElement.dispatchEvent(changeEvent);


    }



  }



  /* smooth scroll */
  /*
      adjustSiteContainer(siteContainer, 1800);
    state.scroll.height = contentContainer.getBoundingClientRect().height;
    body.style.height = `${ Math.floor(state.scroll.height) }px`;
    */
  /* / smooth scroll */


  // Navbar fixed on scroll (sticky top)
  //if(document.getElementById('nav-bar').length > 0){
  window.addEventListener('scroll', function () {
    //console.log(window.scrollY);
    if (window.scrollY > 100) {
      //   document.getElementById('headerContent').classList.add('fixed-top');
      document.getElementById('header').classList.add('fix-header');
      // add padding top to show content behind navbar
      let navbar_height = document.querySelector('.navbar').offsetHeight;
      // document.body.style.paddingTop = navbar_height + 'px';
    } else {
      //   document.getElementById('headerContent').classList.remove('fixed-top');
      document.getElementById('header').classList.remove('fix-header');
      // remove padding top from body
      // document.body.style.paddingTop = '0';
    }
  });

  //}
  var slideElement = document.getElementById('base');
  if (typeof slideElement != 'undefined' && slideElement != null) {
    var base = tns({
      container: '#base',
      items: 1,
      loop: true,
      controls: true,
      controlsText: ["", ""],
      nav: false,
      slideBy: 1,
      mouseDrag: true,
      responsive: {
        700: {
          items: 2
        },
        900: {
          items: 3
        },
        1400: {
          items: 4
        }
      }
    });

  }

  var slideElement21 = document.getElementById('base21');
  if (typeof slideElement21 != 'undefined' && slideElement21 != null) {
    var base21 = tns({
      container: '#base21',
      items: 1,
      loop: true,
      controls: true,
      controlsText: ["", ""],
      nav: false,
      slideBy: 1,
      mouseDrag: true,
      responsive: {
        700: {
          items: 2
        },
        900: {
          items: 3
        },
        1400: {
          items: 5
        }
      }
    });

  }

});
