/* function to animate text on scroll */


  export const centreOfInterest = () => {
    let choice = document.getElementById('interest_choice');
      if(choice){

        choice.addEventListener('change', (event) => {
          const current_choice = choice.value; 
          if(current_choice == 'Autre'){
            console.log('===>',current_choice);
            document.getElementById('interest_choice_autre').style.display = '';

          }else
          {
            document.getElementById('interest_choice_autre').style.display = 'none';
          }
      
        });
    }
  };

  // [forked from](http://jsfiddle.net/FvMYz/)
  // [show-hide-based-on-select-option-jquery)(http://stackoverflow.com/questions/2975521/show-hide-div-based-on-select-option-jquery/2975565#2975565)
  