let applyprogramsubmitted = document.getElementById('apply-program');

if(applyprogramsubmitted)
{ 

    applyprogramsubmitted.addEventListener('click',function(){
        applyprogramsubmitted.disabled = true;
        let program_id = document.getElementById('program_id').value;
        let name = document.getElementById('name').value;
        let email = document.getElementById('email').value;
        let message = document.getElementById('message').value;

        axios.post('/program/applying/'+program_id, {
            name: name,
            email: email,
            message: message,
          })
          .then(function (response) {
    
            if(response.status == 200){
                document.getElementById('success-apply').innerHTML = "Applied succesfuly";
                document.getElementById('name').value = "";
                document.getElementById('email').value = "";
                document.getElementById('message').value = "";
                document.getElementById('success-apply').style.display = "block"
                document.getElementById('program-apply-form').style.display = "none"
                document.getElementById('name_error').innerHTML ="";
                document.getElementById('email_error').innerHTML ="";
                document.getElementById('message_error').innerHTML ="";

            }
            applyprogramsubmitted.disabled = false;
            response = response.json();
            response.then(function (json) {
                
            })
            console.log(response);
          })
          .then(data => {
            console.log(data)
         })
          .catch(function (errors) {
            if (errors.response){


                !errors.response.data.errors.name ? document.getElementById('name_error').innerHTML ='':  document.getElementById('name_error').innerHTML=errors.response.data.errors.name ;
                !errors.response.data.errors.email ? document.getElementById('email_error').innerHTML ='':   document.getElementById('email_error').innerHTML=errors.response.data.errors.email;
                !errors.response.data.errors.message ? document.getElementById('message_error').innerHTML ='':   document.getElementById('message_error').innerHTML=errors.response.data.errors.message;
                applyprogramsubmitted.disabled = false;


            }

          });
       
    });
}

let contactformsubmitted = document.getElementById('apply-contact');
if(contactformsubmitted)
{ 
    contactformsubmitted.addEventListener('click',function(){
        contactformsubmitted.disabled = true;

        let name = document.getElementById('name-contact').value;
        let email = document.getElementById('email-contact').value;
        let message = document.getElementById('message-contact').value;

        axios.post('/contact', {
            name: name,
            email: email,
            message: message,
          })
          .then(function (response) {
    
            if(response.status == 200){
                document.getElementById('success-apply-contact').innerHTML = "Applied succesfuly";
                document.getElementById('name-contact').value = "";
                document.getElementById('email-contact').value = "";
                document.getElementById('message-contact').value = "";
                document.getElementById('success-apply-contact').style.display = "block"
                document.getElementById('send-contact-form').style.display = "none"
                
                document.getElementById('name_error_contact').innerHTML ="";
                document.getElementById('email_error_contact').innerHTML ="";
                document.getElementById('message_error_contact').innerHTML ="";
            }
            contactformsubmitted.disabled = false;
            response = response.json();
            response.then(function (json) {
                
            })
            console.log(response);
          })
          .then(data => {
            console.log(data)
         })
          .catch(function (errors) {
            if (errors.response){


                !errors.response.data.errors.name ? document.getElementById('name_error_contact').innerHTML ='':  document.getElementById('name_error_contact').innerHTML=errors.response.data.errors.name ;
                !errors.response.data.errors.email ? document.getElementById('email_error_contact').innerHTML ='':   document.getElementById('email_error_contact').innerHTML=errors.response.data.errors.email;
                !errors.response.data.errors.message ? document.getElementById('message_error_contact').innerHTML ='':   document.getElementById('message_error_contact').innerHTML=errors.response.data.errors.message;
                contactformsubmitted.disabled = false;

            }

          });
       
    });
}

let newsletterformsubmitted = document.getElementById('apply-newsletter');
if(newsletterformsubmitted)
{ 
  newsletterformsubmitted.addEventListener('click',function(){
    newsletterformsubmitted.disabled = true;

        let email = document.getElementById('email-newsletter').value;

        axios.post('/newsletters', {
            email: email,
          })
          .then(function (response) {
    
            if(response.status == 200){
                document.getElementById('success-apply-newsletter').innerHTML = "Applied succesfuly";
                document.getElementById('email-contact').value = "";
                document.getElementById('success-apply-newsletter').style.display = "block"
                document.getElementById('send-newsletter-form').style.display = "none"
                
                document.getElementById('email_error_newsletter').innerHTML ="";
            }
            contactformsubmitted.disabled = false;
            response = response.json();
            response.then(function (json) {
                
            })
            console.log(response);
          })
          .then(data => {
            console.log(data)
         })
          .catch(function (errors) {
            if (errors.response){
                !errors.response.data.errors.email ? document.getElementById('email_error_newsletter').innerHTML ='':   document.getElementById('email_error_newsletter').innerHTML=errors.response.data.errors.email;
                
            }
            newsletterformsubmitted.disabled = false;
          });
       
    });
}