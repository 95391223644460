/* function to animate text on scroll */

const animate = (element, position) => {
  element.style.transform = `translateX(${position}px)`;
};
export const moveOnScroll = () => {
  document.addEventListener('scroll', function () {
    const arr = document.getElementsByClassName('draggable-wrap');
    console.log();
    Object.keys(arr).forEach((item, index) => {
      const ele = document.getElementById(`draggable-${index + 1}`);
      if(typeof ele != 'undefined' && ele != null){
          const scrolled = document.scrollingElement.scrollTop;
          const position = ele.offsetTop;
          window.requestAnimationFrame(function () {
            const { firstElementChild, lastElementChild } = ele;
            if (window.innerWidth >= 720) {
              animate(firstElementChild, (position - scrolled) * -3);
              animate(lastElementChild, 0);
            //   animate(
            //     lastElementChild,
            //     (position - (scrolled + lastElementChild.offsetWidth)) * 0.3
            //   );
            } else {
              animate(firstElementChild, 0);
              animate(lastElementChild, 0);
            }
          });
      }


    });
  });
};
