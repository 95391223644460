import Splide from '@splidejs/splide';


export const carousel = () => {
  if(document.getElementsByClassName('splide').length){
    new Splide('.splide', {
      pagination: false, // disable pagination
      arrows: true, // disable arraws
      type: 'loop',
      gap: '2rem',
      snap: true,
      perPage: 3,
      perMove: 1,
      breakpoints: {
        960: {
          perPage: 2,
        },
        480: {
          perPage: 1,
        },
      },
    }).mount();
  }


};
